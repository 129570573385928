<template>
    <div>
        <div :style="windowWidth < 600 ? data.MobileStyle : data.Style">
            <div class="absolute top-0 left-0 h-full transition duration-150 hover:bg-black hover:bg-opacity-25 sm:hidden md:flex hidden justify-center text-transparent hover:text-white" @click="slide--" style="width: 100px; z-index: 1;">
                <div class="self-center">
                    <b-icon icon="chevron-left" size="is-large"></b-icon>
                </div>
            </div>
            <div class="absolute top-0 right-0 h-full transition duration-150 hover:bg-black hover:bg-opacity-25 sm:hidden md:flex hidden justify-center text-transparent hover:text-white" @click="slide++" style="width: 100px; z-index: 1;">
                <div class="self-center">
                    <b-icon icon="chevron-right" size="is-large"></b-icon>
                </div>
            </div>
            <b-carousel-list v-if="data.IsLargeImage" v-model="slide" :data="data.Data" :arrow="false" :items-to-show="data.ItemToShow">
                <template slot="item" slot-scope="list">
                    <img :src="windowWidth > 600 ? list.Image : list.MobileImage" class="w-full p-12" :style="windowWidth < 600 ? list.ImageMobileStyle : list.ImageStyle" :class="windowWidth < 600 ? list.ImageMobileClass : list.ImageClass" alt="" style="object-fit: cover;">
                    <div v-if="list.Content" :class="list.Content.Class">
                        <div :class="list.Content.ContainerClass">
                            <span v-if="list.Content.Text.Title" :class="list.Content.Text.TitleClass">{{ list.Content.Text.Title }}</span>
                            <span v-if="list.Content.Text.SubTitle" :class="list.Content.Text.SubTitleClass">{{ list.Content.Text.SubTitle }}</span>
                            <buttons :data="list.Content.Buttons" />
                        </div>
                    </div>
                    <buttons :data="list.Buttons" />
                </template>
            </b-carousel-list>
            <b-carousel-list v-else v-model="slide" :data="data.Data" :arrow="false" :items-to-show="data.ItemToShow">
                <template slot="item" slot-scope="list">
                    <div class="h-full relative w-full flex justify-center" :style="list.Style">
                        <div class="self-center relative" :class="list.ContainerClass">
                            <img :src="windowWidth > 600 ? list.Image : list.MobileImage" class="w-full" :style="windowWidth < 600 ? list.ImageMobileStyle : list.ImageStyle" :class="windowWidth < 600 ? list.ImageMobileClass : list.ImageClass" alt="">
                            <div v-if="list.Content" :class="list.Content.Class">
                                <div :class="list.Content.ContainerClass">
                                    <span v-if="list.Content.Text.Title" :class="list.Content.Text.TitleClass">{{ list.Content.Text.Title }}</span>
                                    <span v-if="list.Content.Text.SubTitle" :class="list.Content.Text.SubTitleClass">{{ list.Content.Text.SubTitle }}</span>
                                    <buttons :data="list.Content.Buttons" />
                                </div>
                            </div>
                            <buttons :data="list.Buttons" />
                        </div>
                    </div>
                </template>
            </b-carousel-list>
        </div>
    </div>
</template>
<script>
export default {
    components: {
        Buttons: () => import('./components/buttons')
    },
    data () {
        return {
            slide: 0
        }
    },
    props: {
        data: {
            type: Object
        }
    }
}
</script>
<style lang="scss" scoped>
    ::v-deep img {
        height: 100%;
    }
    ::v-deep .carousel {
        min-height: fit-content;
    }
    ::v-deep .carousel-list {
        height: 100%;
        box-shadow: none;
        .carousel-slides {
            height: 100%;
        }
    }
</style>
